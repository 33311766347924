// src/components/AdminPanel.js

import React, { useEffect, useState } from 'react';
import { db } from './firebase-config';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Container, Grid, Typography, Badge, Avatar, Card, CardContent, FormControl, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';


const AdminPanel = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate
  const auth = getAuth(); // Get the Firebase Auth instance

  // Logout function
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/signin'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout Error:', error);
      // Optionally handle errors, e.g., display an error message
    }
  };

  const [dataSourceUrl, setDataSourceUrl] = useState('');

  useEffect(() => {
    const fetchDataSourceUrl = async () => {
      const docRef = doc(db, 'settings', 'dataSource');
      try {
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
          setDataSourceUrl(docSnap.data().url);
        } else {
          console.log("No current data source");
        }
      } catch (error) {
        console.error('Error updating data source URL: ', error);
      }
    };

    fetchDataSourceUrl();
  }, []);

  const updateDataSourceUrl = async () => {
    const docRef = doc(db, 'settings', 'dataSource');
    try {
      await setDoc(docRef, { url: dataSourceUrl });
      alert('Data source URL updated successfully.');
    } catch (error) {
      console.error('Error updating data source URL: ', error);
      alert('Failed to update data source URL.');
    }
  };

  return (
    <Container style={{marginTop: '40px', paddingBottom: '40px'}}>
      <Typography variant="h3" component="h1" gutterBottom>
        <a href="/" className="logo">JLandStephareMoving.com</a>
      </Typography>
      <Card style={{marginTop: '40px'}}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Badge overlap="circular">
                <Avatar src='/photo.jpg' alt="Jon-luke and Steph" sx={{ width: 200, height: 200}}></Avatar>
              </Badge>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="h4" component="h2" gutterBottom>
                Admin Panel
              </Typography>
              <Button onClick={handleLogout}>Logout</Button>
              <FormControl fullWidth margin="dense" size='small'>
              <TextField id="data-source-url" label="Data Source URL" variant="outlined" value={dataSourceUrl} onChange={(e) => setDataSourceUrl(e.target.value)}/>
              <Button variant="contained" onClick={updateDataSourceUrl}>Update Source</Button>
            </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Typography variant="body" style={{paddingTop: '40px', paddingBottom: '40px'}}>
        &copy;2024 <a href="/">JLandStephareMoving.com</a> | Made with love.
      </Typography>
    </Container>
  );
};

export default AdminPanel;
