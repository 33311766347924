import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Container, Grid, Alert, Typography, Badge, Avatar, Card, CardContent, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip, Button, Paper, TextField } from '@mui/material';
import ListingCard from './ListingCard';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from './firebase-config';
import { doc, getDoc } from 'firebase/firestore';

const SalesList = () => {
  const [listings, setListings] = useState([]);
  const [error, setError] = useState(null);
  const [filteredlistings, setFilteredlistings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortOrder, setSortOrder] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  

  useEffect(() => {
    const fetchDataSourceUrl = async () => {
      const docRef = doc(db, 'settings', 'dataSource');
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return docSnap.data().url; // Ensure this is the direct URL to the CSV file
      } else {
        throw new Error("No data source URL found in Firestore.");
      }
    };

    const fetchCsvData = async (dataSourceUrl) => {
      const response = await fetch(dataSourceUrl);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const csvText = await response.text();
      return csvText;
    };

    function sanitize(input) {
      const div = document.createElement('div');
      div.textContent = input;
      return div.innerHTML;
    }

    const processData = async () => {
      try {
        const url = await fetchDataSourceUrl();
        const csvData = await fetchCsvData(url); // Make sure to wait for the URL before fetching CSV
        Papa.parse(csvData, {
          header: true,
          complete: function(results) {
            const validatedData = results.data.map(listing => {
              return {
                Photo: sanitize(listing.Photo),
                Name: sanitize(listing.Name),
                Description: sanitize(listing.Description),
                Status: sanitize(listing.Status),
                Price: parseFloat(listing.Price) || 0,
                Category: listing.Category ? listing.Category.split(',').map(cat => cat.trim()) : [],
              };
            });

            const allCategories = validatedData.flatMap(listing => listing.Category);
            const uniqueCategories = Array.from(new Set(allCategories)).filter(Boolean);
            setCategories(uniqueCategories);

            setListings(validatedData);
          },
          error: function(err) {
            console.error('Error parsing CSV: ' + err.message);
            setError('Error parsing CSV: ' + err.message);
          }
        });
      } catch (error) {
        console.error("Error: ", error);
        setError(error.message);
      }
    };

    processData();
  }, []);

  useEffect(() => {
      // Parse URL query parameters and set state accordingly
      const params = queryString.parse(location.search);
      setSelectedCategories(params.categories ? params.categories.split(',') : []);
      setSortOrder(params.sort || '');
    }, [location.search]);
  
    useEffect(() => {
      const updatedListings = listings
        .filter(listing =>
          // Filter by category
          selectedCategories.length === 0 || selectedCategories.every(cat => listing.Category.includes(cat))
        )
        .filter(listing =>
          // Filter by search query (case insensitive)
          listing.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          listing.Description.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
      if (sortOrder === 'asc' || sortOrder === 'desc') {
        updatedListings.sort((a, b) => {
          const priceA = parseFloat(a.Price);
          const priceB = parseFloat(b.Price);
          return sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
        });
      }
    
      setFilteredlistings(updatedListings);
    }, [listings, selectedCategories, sortOrder, searchQuery]);
  
    useEffect(() => {
      // Update URL with current filters and sorting
      const params = queryString.stringify({
        categories: selectedCategories.length > 0 ? selectedCategories.join(',') : undefined,
        sort: sortOrder || undefined,
      }, { skipEmptyString: true });
  
      navigate(`?${params}`, { replace: true });
    }, [selectedCategories, sortOrder, navigate]);
  
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

  return (
    <Container style={{marginTop: '40px', paddingBottom: '40px'}}>
      <Typography variant="h3" component="h1" gutterBottom>
        <a href="/" className="logo">JLandStephareMoving.com</a>
      </Typography>
      <Card style={{marginTop: '40px'}}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Badge overlap="circular">
                <Avatar src='/photo.jpg' alt="Jon-luke and Steph" sx={{ width: 200, height: 200}}></Avatar>
              </Badge>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="h4" component="h2" gutterBottom>
                Hello!
              </Typography>
              <Typography variant="body2" color="text">
                If you are here, it means that you probably know that we are moving! If not, well this is awkward - but we are moving to Mexico! Here's the thing, we need to sell 99% of our stuff. So please help us out and take a look at the items below. This list will be updated regularly and everything must go by May 28th 2024.<br/><br/>Listed prices are open to negotiation. If you are interested or have questions, please reach out to myself or Steph personally.<br/><br/>Agape!<br/>Jon-luke & Stephanie
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Typography variant="h4" component="h3" gutterBottom style={{marginTop: '40px'}}>
        Items for Sale
      </Typography>
      <Paper elevation={1}>
      <Grid container>
        <Grid item sx={{px: 1}}>
          <Typography variant="h6" component="h5" gutterBottom>Filter Items</Typography>
        </Grid>
          <Grid item xs={12} sx={{px: 1}}>
            <TextField
              label="Search Items"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={8}>
          <FormControl fullWidth margin="dense" size='small'>
              <InputLabel id="category-filter-label">Categories</InputLabel>
              <Select
                labelId="category-filter-label"
                id="category-filter-select"
                sx={{mx: 1}}
                multiple
                value={selectedCategories}
                onChange={(event) => setSelectedCategories(event.target.value)}
                input={<OutlinedInput id="select-multiple-chip" label="Category" />}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </div>
                )}
              >
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
          <FormControl fullWidth margin="dense" size='small'>
            <InputLabel id="sort-order-label">Sort By Price</InputLabel>
              <Select
                labelId="sort-order-label"
                id="sort-order-select"
                value={sortOrder}
                sx={{mx: 1}}
                onChange={(event) => setSortOrder(event.target.value)}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="outlined"
              onClick={() => {navigate('?', { replace: true });}}
              sx={{ m: 1 }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid container>
      {filteredlistings.length > 0 ? (
          filteredlistings.map((listing, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <ListingCard listing={listing} />
            </Grid>
          ))
        ) : (
          <Typography variant="body" style={{ marginTop: 20 }}>
            No items are currently available for sale.
          </Typography>
        )}
      </Grid>
      <Typography variant="h4" component="h2" style={{paddingTop: '40px', paddingBottom: '40px', textAlign: 'center'}}>
        That's all Folks!
      </Typography>
      <Typography variant="body" style={{paddingTop: '40px', paddingBottom: '40px'}}>
        &copy;2024 <a href="/">JLandStephareMoving.com</a> | Made with love.
      </Typography>
    </Container>
  );
};

export default SalesList;