import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route, Navigate, HashRouter } from 'react-router-dom';
import SignIn from './SignIn';
import ProtectedRoute from './ProtectedRoute';
import AdminPanel from './admin';
import { auth } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';

function MainApp() {
  const [user, loading, error] = useAuthState(auth);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <HashRouter>
      <div>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default MainApp