import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase-config";
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Card, CardContent, Box, TextField, Button } from '@mui/material';

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in");
      navigate('/admin');
    } catch (error) {
      console.error("Error signing in: ", error.message);
    }
  };

  return (
    <Container style={{marginTop: '40px', paddingBottom: '40px'}}>
      <Typography variant="h3" component="h1" gutterBottom>
        <a href="/" className="logo">JLandStephareMoving.com</a>
      </Typography>
      <Card style={{marginTop: '40px'}}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" component="h2" gutterBottom>
                Log in
              </Typography>
              <form onSubmit={handleSignIn}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <TextField id="email" label="Email" type="email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)}/>
                  <TextField id="password" label="Password" type="password" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)}/>
                  <Button type="submit" variant="contained" >Log in</Button>
                </Box>
            </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Typography variant="body" style={{paddingTop: '40px', paddingBottom: '40px'}}>
        &copy;2024 <a href="/">JLandStephareMoving.com</a> | Made with love.
      </Typography>
    </Container>
  );
};

export default SignIn;
