// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-Aoh3pV7mToapBTrFPkNYaCcI5Nto8nM",
  authDomain: "home-listing-ec6b7.firebaseapp.com",
  projectId: "home-listing-ec6b7",
  storageBucket: "home-listing-ec6b7.appspot.com",
  messagingSenderId: "742482333348",
  appId: "1:742482333348:web:58e3bfa2632607cd8fa031",
  measurementId: "G-ML1V23YZHF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app);

export {auth, db}