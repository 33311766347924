// src/ListingCard.js
import React from 'react';
import { Card, CardMedia, CardContent, Typography, IconButton, Box, Chip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

const ListingCard = ({ listing }) => {
  const { Photo, Name, Description, Status, Price } = listing;
  const statusColor = listing.Status.toLowerCase() === 'sold' ? 'error.main': (listing.Status.toLowerCase() === 'pending' ? 'warning.main':'primary.main');
  const slug = slugify(listing.Name);

  const handleShare = async () => {
    if (navigator.share) {
        try {
            await navigator.share({
                title: listing.Name,
                text: `Checkout this item: ${listing.Name}`,
                url: 'https://jlandstepharemoving.com/#' + slug,
            })
        } catch (error) {
            console.log('Error sharing:', error);
        }
    } else {
        console.log("Web Share API is not support in your browser");
    }
  };

  return (
    <Card id={slug} sx={{ maxWidth: 345, margin: 2 }}>
      {listing.Photo && (
        <CardMedia
          component="img"
          height="140"
          image={Photo}
          alt={Name}
        />
      )}
      <Box position='relative'>
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            {Name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            {Description}
            </Typography>
            <Typography variant="body1" color={statusColor}>
            Status: {Status}
            </Typography>
            <Typography variant="body1">
            Price: ${Price.toFixed(2)}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: 2 }}>
              {listing.Category.map((category, index) => (
                <Chip key={index} label={category} />
              ))}
            </Box>
            {navigator.share && (
            <IconButton onClick={handleShare} aria-label='Share' sx={{position: 'absolute', top: 8, right: 8}}>
                <ShareIcon />
            </IconButton>
            )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default ListingCard;

function slugify(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }